
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { React, useState } from "react";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function CustomizeDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle  {...other}>
            {children}
            {onClose ? (
                <IconButton
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export const VerificationModal = (props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };
    return (
        <div>
            <Dialog className="commModal"
                open={props.open}
                onClose={props.onClose}>
                    <CustomizeDialogTitle onClose={props.onClose}>
                        <span>Verification</span>
                    </CustomizeDialogTitle>
                <DialogContent>
                    <div className="verificationModal">
                        <div className="modalImgIcon">
                            <img src="/assets/images/verification.png" alt="" />
                        </div>
                        <div className="modalDesc">
                            <p>Please, approve the transaction by verifying your account in your Nuritopia Wallet</p>
                        </div>
                        <div className="modalTimer">
                            <div className="modalTimerDesc">
                                <div className="timeIcon"><AccessTimeIcon/></div>
                                <p>Time Left 02:50</p>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                {/* <DialogActions>
                    <button onClick={handleOpen} className="hlf-btn modalBtn">
                        Next
                    </button>
                    <button onClick={props.onClose} className="hlf-btn modalBtn borderBtn">
                        cancel
                    </button>
                </DialogActions> */}
            </Dialog>
        </div>
    )
}