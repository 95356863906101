import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelect } from "../hooks/useSelect";

export const AssetBox = (props) => {
    const [select, setSelect] = useState(false);
    const navigate = useNavigate();
    const {myNftWallet, isBarVisible} = useSelect();
    const handleClickSelect = () => {
        myNftWallet(true);
        setSelect(true);
    }
    const handleClick = () => {
        navigate('/my-nfts/send-nft');
    }
    const handleClickCreator = () => {
        navigate('/creator')
    }
    return (
        <div className="col-md-3 col-sm-12">
            <div className={(select && isBarVisible) ? "cat-box select" : "cat-box"}>
                <div className="img-box" onClick={handleClick}>
                    { isBarVisible ?
                    <div className="select-cat">
                        <img src="/assets/images/select-icon.png" alt="" />
                    </div>
                    : ''}
                    <img src={props.image} alt="" />
                </div>
                <div className="cat-info">
                    <div className="top-bar">
                        <div className="panel">
                            <div className="icon">
                                <img src="/assets/images/n-logo-img.png" alt="" />
                            </div>
                            <div className="text" onClick={handleClickCreator}>
                                <p>Nurithopia</p>
                            </div>
                        </div>
                        <div className="number">
                            <p>475684</p>
                        </div>
                    </div>
                    <Link to='/my-nfts/send-nft'>Exythrabdg</Link>
                </div>
                <div className="bottom-panel">
                    <div className="plus-icon">
                       <img src="/assets/images/plus-icon.png" alt="" onClick={handleClickSelect} />
                    </div>
                    <div className="lt-side">
                        <img src="/assets/images/paly-icon.png" alt="" />
                        <span>250</span>
                    </div>
                </div>
            </div>
        </div>
    );
};