import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useSelect } from "../../hooks/useSelect";

function CustomizeDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle  {...other}>
            {children}
            {onClose ? (
                <IconButton
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export const SearchIDModal = (props) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const {isBarVisible} = useSelect();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };
    const handleClick = () => {
    isBarVisible ? navigate('/my-nfts/multiple-nft-send-next') : navigate('/my-nfts/send-nft-send-next');
    }

    return (
        <div>
            <Dialog className="commModal"
                open={props.open}
                onClose={props.onClose}>
                <CustomizeDialogTitle className="no-border-bt" onClose={props.onClose}>
                    <span>Search ID</span>
                </CustomizeDialogTitle>
                <DialogContent>
                    <div className="searchid-wrap">
                        <div className="search-field">
                            <input type="text" placeholder="" className="form-control" />
                            <button className="btn"><img src="/assets/images/src-icon.png" alt="" /></button>
                        </div>
                        <p>Enter the Nuritopia ID of the beneficiary</p>
                        <strong>Search Result</strong>
                        <div className="src-list">
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel labelPlacement="start" value="female" control={<Radio />} label="02e2356ef7569855ghj588998444fd5d55dfg" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={() => { handleClick(); props.onClose()}} className="hlf-btn modalBtn">
                        Next
                    </button>
                    <button onClick={props.onClose} className="hlf-btn modalBtn borderBtn">
                        cancel
                    </button>
                </DialogActions>
            </Dialog>
        </div>

    )
}