import React, { useState } from "react";
import { VerificationModal } from "../../../components/Modals/VerificationModal";

export const MultipleNftDelist = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="body-full-height">
                <div className="selection-list-sec">
                    <div className="cus-container">
                        <h2>Selection List</h2>
                        <div className="selection-list-table">
                            <div className="table-responsive">
                                <table className="table comm-table">
                                    <thead>
                                        <tr>
                                            <th>Character Name</th>
                                            <th>Token ID</th>
                                            <th>Class</th>
                                            <th>Creator</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="tb-pro-name">
                                                    <span className="tham"><img src="/assets/images/cart-pro-img1.png" alt="" /></span>
                                                    <p>Exythrabdg 1</p>
                                                </div>
                                            </td>
                                            <td>475684</td>
                                            <td>Land</td>
                                            <td>Nuritopia</td>
                                            <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="tb-pro-name">
                                                    <span className="tham"><img src="/assets/images/cart-pro-img2.png" alt="" /></span>
                                                    <p>Exythrabdg 1</p>
                                                </div>
                                            </td>
                                            <td>475684</td>
                                            <td>Land</td>
                                            <td>Nuritopia</td>
                                            <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="tb-pro-name">
                                                    <span className="tham"><img src="/assets/images/cart-pro-img3.png" alt="" /></span>
                                                    <p>Exythrabdg 1</p>
                                                </div>
                                            </td>
                                            <td>475684</td>
                                            <td>Land</td>
                                            <td>Nuritopia</td>
                                            <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-buy-status-bar">
                    <div className="cus-container">
                        <div className="buy-status-wrap no-border-tp justify-content-end">
                            <div className="comm-btn">
                                <button className="sell-btn" onClick={handleOpen}>Delist</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VerificationModal
                open={open}
                onClose={handleClose} />
        </>
    )
}