import React, { useState } from "react";
import { VerificationModal } from "../../../../components/Modals/VerificationModal";

export const SendPage = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };

    return (
        <>
            <div className="asset-details-sec">
                <div className="cus-container">
                    <div className="asset-details-wrap">
                        <div className="ttl">
                            <h2>Land L</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <div className="img-box">
                                    <img src="/assets/images/asset-details.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-7 col-sm-12">
                                <div className="asset-details-info">
                                    <div className="details-info-list">
                                        <div className="row">
                                            <div className="col-md-8 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Character Name</span>
                                                    <p>LandL</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Token ID</span>
                                                    <p>44477</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="send-nft-info">
                                                    <div className="sendingUuesrDetails">
                                                        <h4>Sending to</h4>
                                                        <div className="userInfoPanel">
                                                            <div className="userInfo">
                                                                <span>S</span>
                                                                <p>Sorae22</p>
                                                            </div>
                                                            <div className="edit">
                                                                <img src="images/edit-icon.png" alt="" />
                                                                <p>Edit Details</p>
                                                            </div>
                                                        </div>
                                                        <div className="comm-srcbar">
                                                            <input type="text" className="form-control" />
                                                            <div className="icon">
                                                                <img src="/assets/images/src-icon.png" alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="send-nft-price">
                                                        <div className="common-price">
                                                            <p>Price</p>
                                                            <div className="price-box">
                                                                <img src="/assets/images/paly-icon.png" alt="" />
                                                                <span>50.00</span>
                                                            </div>
                                                        </div>
                                                        <div className="gasfree">
                                                            <p>Gas Fee</p>
                                                            <h4>0.00 NBLU</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price-buy-wrap cus-border-top">
                                        <div className="price">
                                        </div>
                                        <div className="buy-btn d-flex">
                                            <button className="" onClick={handleOpen}>Send</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="history-table">
                            <h2>Transaction History</h2>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>TXN</th>
                                            <th>To</th>
                                            <th>From</th>
                                            <th>Price</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Ox1374..1173FD</td>
                                            <td>0X8C01..BD38ef</td>
                                            <td>0XAF26..B05A97</td>
                                            <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                            <td>Dec 06, 2022 20:47:36</td>
                                        </tr>
                                        <tr>
                                            <td>Ox1374..1173FD</td>
                                            <td>0X8C01..BD38ef</td>
                                            <td>0XAF26..B05A97</td>
                                            <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                            <td>Dec 06, 2022 20:47:36</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VerificationModal open={open}
                onClose={handleClose} />
        </>
    )
}