import { FormControl, MenuItem, Select } from "@mui/material";
import { React, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelect } from "../hooks/useSelect";
import { CartModal } from "../views/Auth/CartModal";

export const Header = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { isCartVisible } = useSelect();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);

  };
  return (
    <>
      <header className="header">
        <div className="header-container">
          <div className="header-wrap">
            <div className="logo">
              <Link to='/'>
                <img src="/assets/images/logo.png" alt="" />
              </Link>
            </div>
            <div className="hd-src">
              <div className="src-icon">
                <img src="/assets/images/src-icon.png" alt="" />
              </div>
              <input type="text" placeholder="Search by Item name, NFT ID" className="src-field" />
            </div>
            <div className="nav-sec">
              <div className="stellarnav">
                <ul>
                  <li className={location.pathname.includes('matketplace') || location.pathname === '/' ? "active" : ""}><Link to='/'>Marketplace</Link></li>
                  <li className={location.pathname.includes('my-nfts') ? "active" : ""}><Link to='/my-nfts'>My NFTs</Link></li>
                  <li className={location.pathname.includes('minting') ? "active" : ""}><Link to='/minting'>Minting</Link></li>
                  <li className={location.pathname.includes('auction') ? "active" : ""}><Link to='/auction'>Auction</Link></li>
                  <li className={location.pathname.includes('blog') ? "active" : ""}><Link to='/blog'>Blog</Link></li>
                  <li className={location.pathname.includes('docs') ? "active" : ""}><Link to='/docs'>Docs</Link></li>
                </ul>
              </div>
              {/* <div className="crypto-select">
                <select className="vodiapicker">
                  <option value="en" className="test" data-thumbnail="/assets/images/eth-icon.png">Eth</option>
                </select>
                <div className="lang-select">
                  <button className="btn-select" value="">

                  <li><img src="/assets/images/eth-icon.png" alt="" value="en"/><span>Eth</span></li>
                  </button>
                  <div className="b">
                    <ul id="a"></ul>
                  </div>
                </div>
              </div> */}
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  defaultValue={'Eth'}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  className="MuiSelectOutlined"
                >
                  <MenuItem value={'Eth'}><em><img src="/assets/images/eth-icon.png" /> Eth</em></MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="cart-user-info">
              {isCartVisible ? <div className="cart-icon" onClick={handleOpen}>
                <img src="/assets/images/cart-icon.png" alt="" />
                <span>10</span>
              </div> : <></>}
              <div className="user-info">
                <strong>C</strong>
                <p>Hi, Cameron</p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <CartModal open={open}
        onClose={handleClose} />
    </>

  );

}