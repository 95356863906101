import React, { useState } from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useSelect } from "../../hooks/useSelect";

function CustomizeDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle  {...other}>
            {children}
            {onClose ? (
                <IconButton
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export const RecentAddressModal = (props) => {
    const [open, setOpen] = useState(false); 
    const {isBarVisible} = useSelect();
    const navigate = useNavigate();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };
    const handleClick = () => {
        isBarVisible ? navigate('/my-nfts/multiple-nft-send-next') : navigate('/my-nfts/send-nft-send-next');
    }

    return (
        <div>
            <Dialog className="commModal"
                open={props.open}
                onClose={props.onClose}>
                <CustomizeDialogTitle onClose={props.onClose}>
                    <span>Recent Address</span>
                </CustomizeDialogTitle>
                <DialogContent>
                    <div className="recent-add-wrap">
                        <h4>Select Address from the below</h4>
                        <div className="src-list">
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel labelPlacement="start" value="female1" control={<Radio />} label="02e2356ef7569855ghj588998444fd5d55dfg" />
                                    <FormControlLabel labelPlacement="start" value="male2" control={<Radio />} label="02e2356ef7569855ghj588998444fd5d55dfg" />
                                    <FormControlLabel labelPlacement="start" value="other3" control={<Radio />} label="02e2356ef7569855ghj588998444fd5d55dfg" />
                                    <FormControlLabel labelPlacement="start" value="male4" control={<Radio />} label="02e2356ef7569855ghj588998444fd5d55dfg" />
                                    <FormControlLabel labelPlacement="start" value="other5" control={<Radio />} label="02e2356ef7569855ghj588998444fd5d55dfg" />
                                    <FormControlLabel labelPlacement="start" value="male6" control={<Radio />} label="02e2356ef7569855ghj588998444fd5d55dfg" />
                                    <FormControlLabel labelPlacement="start" value="other7" control={<Radio />} label="02e2356ef7569855ghj588998444fd5d55dfg" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={() => { handleClick(); props.onClose()}} className="hlf-btn modalBtn">
                        Next
                    </button>
                    <button onClick={props.onClose} className="hlf-btn modalBtn borderBtn">
                        cancel
                    </button>
                </DialogActions>
            </Dialog>
        </div>

    )
}