import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { VerificationModal } from "../../../../components/Modals/VerificationModal";
import { TabPanel } from "../../../../components/TabPanel";

export const SingleNftSell = () => {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [labels, setLabels] = useState('Reverse Price(optional)');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleClick = (e) => {
        if (e.target.value === 'Declining') {
            setLabels('Ending Price (NBLU)');
        } else {
            setLabels('Reverse Price(optional)');
        }
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="asset-details-sec">
                <div className="cus-container">
                    <div className="asset-details-wrap">
                        <div className="ttl">
                            <h2>Land L</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <div className="img-box">
                                    <img src="/assets/images/asset-details.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-7 col-sm-12">
                                <div className="asset-tabheader">
                                    <Tabs value={value} onChange={handleChange}>
                                        <Tab style={{ textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="Fixed Price" />
                                        <Tab style={{ textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="Auction" />
                                    </Tabs>
                                </div>
                                <TabPanel value={value} index={0}>
                                    <div className="sing-asset-details-info">
                                        <div className="sing-details-info-list pt-3">
                                            <div className="row">
                                                <div className="col-md-8 col-sm-12">
                                                    <div className="sing-asset-details-box">
                                                        <span>Character Name</span>
                                                        <p>LandL</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="sing-asset-details-box">
                                                        <span>Token ID</span>
                                                        <p>44477</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="price-buy-wrap position-none pt-3 pb-3 cus-border-bottom cus-border-top mb-3 mt-3">
                                                <div className="price">
                                                    <p>Price</p>
                                                    <div className="price-box">
                                                        <img src="/assets/images/paly-icon.png" alt="" />
                                                        <span>250.00</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="selling-form">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Selling Price (NBLU)</label>
                                                            <input type="text" name="" value="" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="comm-gass-free">
                                                            <span>Gas Fee</span>
                                                            <p>2.00 NBLU</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="price-buy-wrap position-none justify-content-end cus-border-top mt-4">
                                                <div className="buy-btn">
                                                    <button onClick={handleOpen}>Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div className="sing-asset-details-info">
                                        <div className="sing-details-info-list pt-3">
                                            <div className="row">
                                                <div className="col-md-8 col-sm-12">
                                                    <div className="sing-asset-details-box">
                                                        <span>Character Name</span>
                                                        <p>LandL</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="sing-asset-details-box">
                                                        <span>Token ID</span>
                                                        <p>44477</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="price-buy-wrap position-none pt-3 pb-3 cus-border-bottom cus-border-top mb-3 mt-3">
                                                <div className="price">
                                                    <p>Price</p>
                                                    <div className="price-box">
                                                        <img src="/assets/images/paly-icon.png" alt="" />
                                                        <span>250.00</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="selling-form">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Selling Price (NBLU)</label>
                                                            <input type="text" name="" value="" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label>{labels}</label>
                                                            <input type="text" name="" value="" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Method</label>
                                                            <select className="form-control" onChange={handleClick} >
                                                                <option value="Highest" >Selling to Highest Bidder</option>
                                                                <option value="Declining" >Selling with Declining Price</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label>Duration</label>
                                                            <select className="form-control">
                                                                <option>1 Days</option>
                                                                <option>2 Days</option>
                                                                <option>3 Days</option>
                                                                <option>4 Days</option>
                                                                <option>5 Days</option>
                                                                <option>6 Days</option>
                                                                <option>7 Days</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cost-destilas justify-content-end">
                                                <div className="info">
                                                    <ul>
                                                        <li>Service Fee : <span>2.5 %</span></li>
                                                        <li>Creator Fee : <span>3 %</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="price-buy-wrap position-none justify-content-end cus-border-top mt-4">
                                                <div className="buy-btn">
                                                    <button onClick={handleOpen}>Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </div>
                        </div>
                        <div className="history-table">
                            <h2>Transaction History</h2>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>TXN</th>
                                            <th>To</th>
                                            <th>From</th>
                                            <th>Price</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Ox1374..1173FD</td>
                                            <td>0X8C01..BD38ef</td>
                                            <td>0XAF26..B05A97</td>
                                            <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                            <td>Dec 06, 2022 20:47:36</td>
                                        </tr>
                                        <tr>
                                            <td>Ox1374..1173FD</td>
                                            <td>0X8C01..BD38ef</td>
                                            <td>0XAF26..B05A97</td>
                                            <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                            <td>Dec 06, 2022 20:47:36</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VerificationModal open={open}
                onClose={handleClose} />
        </>
    )
}