import React, { useState } from "react";
import { VerificationModal } from "../../../../components/Modals/VerificationModal";

export const SendPageMultiple = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };
    return (
        <>
            <div className="selection-list-sec">
                <div className="cus-container">
                    <h2>Selection List</h2>
                    <div className="selection-list-table">
                        <div className="table-responsive"></div>
                        <table className="table comm-table">
                            <thead>
                                <tr>
                                    <th>Character Name</th>
                                    <th>Token ID</th>
                                    <th>Class</th>
                                    <th>Creator</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="tb-pro-name">
                                            <span className="tham"><img src="/assets/images/cart-pro-img1.png" alt="" /></span>
                                            <p>Exythrabdg 1</p>
                                        </div>
                                    </td>
                                    <td>475684</td>
                                    <td>Land</td>
                                    <td>Nuritopia</td>
                                    <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="tb-pro-name">
                                            <span className="tham"><img src="/assets/images/cart-pro-img2.png" alt="" /></span>
                                            <p>Exythrabdg 1</p>
                                        </div>
                                    </td>
                                    <td>475684</td>
                                    <td>Land</td>
                                    <td>Nuritopia</td>
                                    <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="tb-pro-name">
                                            <span className="tham"><img src="/assets/images/cart-pro-img3.png" alt="" /></span>
                                            <p>Exythrabdg 1</p>
                                        </div>
                                    </td>
                                    <td>475684</td>
                                    <td>Land</td>
                                    <td>Nuritopia</td>
                                    <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="price-table-info">
                            <div className="table-info-wrap">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td><span>Gas Fee</span></td>
                                            <td><p>50.00 NBLU</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="selection-list-filterbar">
                <div className="cus-container">
                    <div className="border-wrap topBor">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="comm-srcbar mb-0">
                                    <input type="text" className="form-control" />
                                    <div className="icon">
                                        <img src="/assets/images/src-icon.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className="userInfoPanelEdit">
                                    <div className="userInfo">
                                        <span>S</span>
                                        <p>Sorae22</p>
                                    </div>
                                    <div className="edit">
                                        <img src="/assets/images/edit-icon.png" alt="" />
                                        <p>Edit Details</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-buy-status-bar">
                <div className="cus-container">
                    <div className="buy-status-wrap justify-content-end">
                        <div className="comm-btn">
                            <button onClick={handleOpen}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <VerificationModal open={open}
                onClose={handleClose} />
        </>
    )
}