import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  isCartVisible: false,
  isBarVisible: false,
  isDelistBarVisible: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'MARKET_PLACE': {
      return {
        ...state,
        isCartVisible: action.payload,
      };
    }
    case 'MY_NFT_WALLET': {
      return {
        ...state,
        isBarVisible: action.payload,
      };
    }
    case 'MY_NFT_SALE': {
      return {
        ...state,
        isDelistBarVisible: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const MultipleSelectContext = createContext({
  ...initialState,
  marketPlace: () => { },
  myNftWallet: () => { },
  myNftSale: () => { },
});

export const MultipleSelectProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const marketPlace = (payload) => {
    dispatch({ type: 'MARKET_PLACE', payload })
  }
  const myNftWallet = (payload) => {
    dispatch({ type: 'MY_NFT_WALLET', payload })
  }
  const myNftSale = (payload) => {
    dispatch({ type: 'MY_NFT_SALE', payload })
  }

  return (
    <MultipleSelectContext.Provider
      value={{
        ...state,
        marketPlace,
        myNftWallet,
        myNftSale,
      }}
    >
      {children}
    </MultipleSelectContext.Provider>
  );
};
// export const useSelect = () => useContext(MultipleSelectContext);


export default MultipleSelectContext;