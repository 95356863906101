import React from "react";
import { Link } from 'react-router-dom';

export const Footer = () => {
     
    return (
        <footer className="footer">
  <div className="cus-container">
    <div className="footer-wrap">
      <div className="ft-menu">
        <ul>
          <li><Link to='/faq'>FAQ</Link></li>
          <li><Link to='/community'>Community</Link></li>
          <li><Link to='/documentation'>Documentation</Link></li>
          <li><Link to='/customer-support'>Customer Support</Link></li>
        </ul>
      </div>
      <div className="ft-social">
        <ul>
          <li><Link to='/facebook'><i className="fa fa-facebook"></i></Link></li>
          <li><Link to='/twitter'><i className="fa fa-twitter"></i></Link></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
    )
}