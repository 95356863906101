import React from 'react';
import { CreationBox } from '../../../components/CreationBox';

export const MyCreation = () => {

    return (
        <>
         <div className="cat-ttl no-border-b pb-0 pt-4">
                            <h2>All Asset (450 items)</h2>
                            <div className="cat-filter">
                                <select className="form-control">
                                    <option value="">Lowest Price</option>
                                    <option value="">Highest Price</option>
                                    <option value="">My Creation</option>
                                    <option value="">Game Asset</option>
                                    <option value="">Land Asset</option>
                                    <option value="">Mobility Asset</option>
                                    <option value="">Pet Asset</option>
                                    <option value="">Building Asset</option>
                                </select>
                            </div>
                        </div>
                        <div className="cat-list-wrap">
                            <div className="row">
                                <CreationBox image={"/assets/images/cat-img1.png"} />
                                <CreationBox image={"/assets/images/cat-img2.png"} />
                                <CreationBox image={"/assets/images/cat-img3.png"} />
                                <CreationBox image={"/assets/images/cat-img4.png"} />
                                <CreationBox image={"/assets/images/cat-img5.png"} />
                                <CreationBox image={"/assets/images/cat-img6.png"} />
                                <CreationBox image={"/assets/images/cat-img7.png"} />
                                <CreationBox image={"/assets/images/cat-img8.png"} />
                            </div>
                        </div>
        </>
    )
}