import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DelistBox } from '../../../components/DelistBox';
import { useSelect } from '../../../hooks/useSelect';

export const Sale = () => {
    
    const { myNftSale, isDelistBarVisible } = useSelect();
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleClickDelist = () => {
        navigate('/my-nfts/multiple-nft-delist');
    }
    const handleCancelSale = () => {
        myNftSale(false);
    }

    return (
        <>
            <div className="cat-ttl pt-4">
                <h2>All Asset (08 items)</h2>
                <div className="cat-filter">
                    <select className="form-control">
                        <option value="">Lowest Price</option>
                        <option value="">Highest Price</option>
                        <option value="">My Creation</option>
                        <option value="">Game Asset</option>
                        <option value="">Land Asset</option>
                        <option value="">Mobility Asset</option>
                        <option value="">Pet Asset</option>
                        <option value="">Building Asset</option>
                    </select>
                </div>
            </div>
            <div className="cat-list-wrap">
                <div className="row">
                    <DelistBox image={"/assets/images/cat-img1.png"} />
                    <DelistBox image={"/assets/images/cat-img2.png"} />
                    <DelistBox image={"/assets/images/cat-img3.png"} />
                    <DelistBox image={"/assets/images/cat-img4.png"} />
                    <DelistBox image={"/assets/images/cat-img5.png"} />
                    <DelistBox image={"/assets/images/cat-img6.png"} />
                    <DelistBox image={"/assets/images/cat-img7.png"} />
                    <DelistBox image={"/assets/images/cat-img8.png"} />
                </div>
            </div>
            {(isDelistBarVisible && location.pathname === '/my-nfts') ?
                <div className="select-item-bar">
                    <div className="cus-container">
                        <div className="select-item-wrap">
                            <div className="item-select">
                                <div className="icon">
                                    <img src="/assets/images/select-item-icon.png" alt="" />
                                    <div className="select-tool">
                                        <div className="tool-wrap"><p>Select All</p></div>
                                    </div>
                                </div>
                                <p className="pl-2">1 Item Selected</p>
                            </div>
                            <div className="comm-btn justify-content-center">
                                <button className="mr-3" onClick={handleCancelSale}>Cancel</button>
                                <button onClick={handleClickDelist}>Next</button>
                            </div>
                        </div>
                    </div>
                </div> : <></>}
        </>
    )
}