import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

export const HomeLayout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};