import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RecentAddressModal } from "../../../../components/Modals/RecentAddressModal";
import { SearchIDModal } from "../../../../components/Modals/SearchIDModal";

export const NextPage = () => {
const [search, setSearch] = useState(false);
const [address, setAddress] = useState(false);
const navigate = useNavigate();
const handleClick = () => {
navigate("/my-nfts/send-nft-send-next");
}
const handleOpenSearch = () => {
setSearch(true);
};
const handleCloseSearch = () => {
setSearch(false);
};
const handleOpenAddress = () => {
setAddress(true);
};
const handleCloseAddress = () => {
setAddress(false);
};
return (
<>
    <div className="asset-details-sec">
        <div className="cus-container">
            <div className="asset-details-wrap">
                <div className="ttl">
                    <h2>Land L</h2>
                </div>
                <div className="row">
                    <div className="col-md-5 col-sm-12">
                        <div className="img-box">
                            <img src="/assets/images/asset-details.png" alt="" />
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12">
                        <div className="asset-details-info">
                            <div className="details-info-list">
                                <div className="row">
                                    <div className="col-md-8 col-sm-12">
                                        <div className="asset-details-box">
                                            <span>Character Name</span>
                                            <p>LandL</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="asset-details-box">
                                            <span>Token ID</span>
                                            <p>44477</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                        <div className="details-info-filter-wrap">
                                            <div className="comm-srcbar">
                                                <input type="text" className="form-control" />
                                                <div className="icon">
                                                    <img src="/assets/images/src-icon.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="filter-btn-panel" onClick={handleOpenSearch}>
                                                        <div className="box">
                                                            <div className="icon">
                                                                <img src="/assets/images/wt-src-icon.png" alt="" />
                                                            </div>
                                                            <div className="text">
                                                                <p>Search ID</p>
                                                            </div>
                                                        </div>
                                                        <div className="arrow">
                                                            <img src="/assets/images/right-arrow.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="filter-btn-panel" onClick={handleOpenAddress}>
                                                        <div className="box">
                                                            <div className="icon">
                                                                <img src="/assets/images/address-icon.png" alt="" />

                                                            </div>
                                                            <div className="text">
                                                                <p>Recent address</p>
                                                            </div>
                                                        </div>
                                                        <div className="arrow">
                                                            <img src="/assets/images/right-arrow.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="filter-btn-panel">
                                                        <div className="box">
                                                            <div className="icon">
                                                                <img src="/assets/images/paste-icon.png" alt="" />
                                                            </div>
                                                            <div className="text">
                                                                <p>Paste</p>
                                                            </div>
                                                        </div>
                                                        <div className="arrow">
                                                            <img src="/assets/images/right-arrow.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="price-buy-wrap">
                                <div className="price">
                                    <p>Price</p>
                                    <div className="price-box">
                                        <img src="/assets/images/paly-icon.png" alt="" />
                                        <span>50.00</span>
                                    </div>
                                </div>
                                <div className="buy-btn d-flex">
                                    <button onClick={handleClick} className="">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="history-table">
                    <h2>Transaction History</h2>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>TXN</th>
                                    <th>To</th>
                                    <th>From</th>
                                    <th>Price</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Ox1374..1173FD</td>
                                    <td>0X8C01..BD38ef</td>
                                    <td>0XAF26..B05A97</td>
                                    <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                    <td>Dec 06, 2022 20:47:36</td>
                                </tr>
                                <tr>
                                    <td>Ox1374..1173FD</td>
                                    <td>0X8C01..BD38ef</td>
                                    <td>0XAF26..B05A97</td>
                                    <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                    <td>Dec 06, 2022 20:47:36</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SearchIDModal open={search} onClose={handleCloseSearch} />
    <RecentAddressModal open={address} onClose={handleCloseAddress} />
</>
)

}