import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssetBox } from '../../../components/AssetBox';
import { useSelect } from '../../../hooks/useSelect';

export const Wallet = () => {
    const { myNftWallet,isBarVisible } = useSelect();
    const navigate = useNavigate();
    const location = useLocation();
    const handleWallet = () => {
        navigate('/my-nfts/multiple-nft');
    }
    const handleCancelWallet = () => {
        myNftWallet(false);
    }

    return (
        <>
            <div className="cat-ttl pt-4">
                <h2>All Asset (08 items)</h2>
                <div className="cat-filter">
                    <select className="form-control">
                        <option value="">Lowest Price</option>
                        <option value="">Highest Price</option>
                        <option value="">My Creation</option>
                        <option value="">Game Asset</option>
                        <option value="">Land Asset</option>
                        <option value="">Mobility Asset</option>
                        <option value="">Pet Asset</option>
                        <option value="">Building Asset</option>
                    </select>
                </div>
            </div>
            <div className="cat-list-wrap">
                <div className="row">
                    <AssetBox image={"/assets/images/cat-img1.png"} />
                    <AssetBox image={"/assets/images/cat-img2.png"} />
                    <AssetBox image={"/assets/images/cat-img3.png"} />
                    <AssetBox image={"/assets/images/cat-img4.png"} />
                    <AssetBox image={"/assets/images/cat-img5.png"} />
                    <AssetBox image={"/assets/images/cat-img6.png"} />
                    <AssetBox image={"/assets/images/cat-img7.png"} />
                    <AssetBox image={"/assets/images/cat-img8.png"} />
                </div>
            </div>

            {(isBarVisible && location.pathname === '/my-nfts') ? <div className="select-item-bar">
                <div className="cus-container">
                    <div className="select-item-wrap">
                        <div className="item-select">
                            <div className="icon">
                                <img src="/assets/images/select-item-icon.png" alt="" />
                                <div className="select-tool">
                                    <div className="tool-wrap"><p>Select All</p></div>
                                </div>
                            </div>
                            <p className="pl-2">1 Item Selected</p>
                        </div>
                        <div className="comm-btn justify-content-center">
                            <button className="mr-3" onClick={handleCancelWallet}>Cancel</button>
                            <button onClick={handleWallet}>Next</button>
                        </div>
                    </div>
                </div>
            </div> : <></>}
        </>
    )
}