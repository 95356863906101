import React from "react";
import { Link } from 'react-router-dom';
import { ClassBox } from "../../../components/ClassBox";
import { CatBox } from "../../../components/CatBox";
import { Header } from "../../../components/Header";
import { Footer } from "../../../components/Footer";

export const Home = () => {
    
    return (
        <>
            <Header />
            <div className="card-list-sec">
                <div className="cus-container">
                    <div className="card-list-wrap">
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <div className="card-item">
                                    <img src="/assets/images/nft-icon.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="card-item">
                                    <img src="/assets/images/image2.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="card-item">
                                    <img src="/assets/images/btc-img.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cat-sec">
                <div className="cus-container">
                    <div className="cat-ttl">
                        <h2>All Category</h2>
                        <div className="cat-filter">
                            <select className="form-control">
                                <option value="">Most Viewed</option>
                                <option value="">Recent Viewed</option>
                            </select>
                        </div>
                    </div>
                    <div className="cat-panel">
                        <h3>Most Viewed</h3>
                        <Link to='/matketplace/most-viewed'>See all</Link>
                    </div>
                    <div className="cat-list-wrap">
                        <div className="row">
                            <CatBox image={"/assets/images/cat-img1.png"} />
                            <CatBox image={"/assets/images/cat-img2.png"} />
                            <CatBox image={"/assets/images/cat-img3.png"} />
                            <CatBox image={"/assets/images/cat-img4.png"} />
                        </div>
                    </div>
                    <div className="cat-panel">
                        <h3>New Listing</h3>
                        <Link to='/matketplace/new-listing'>See all</Link>
                    </div>
                    <div className="cat-list-wrap">
                        <div className="row">
                            <CatBox image={"/assets/images/cat-img5.png"} />
                            <CatBox image={"/assets/images/cat-img6.png"} />
                            <CatBox image={"/assets/images/cat-img7.png"} />
                            <CatBox image={"/assets/images/cat-img8.png"} />
                        </div>
                    </div>
                    <div className="cat-panel">
                        <h3>Recent Deals</h3>
                        <Link to='/matketplace/recent-deals'>See all</Link>
                    </div>
                    <div className="cat-list-wrap">
                        <div className="row">
                            <CatBox image={"/assets/images/cat-img9.png"} />
                            <CatBox image={"/assets/images/cat-img10.png"} />
                            <CatBox image={"/assets/images/cat-img11.png"} />
                            <CatBox image={"/assets/images/cat-img12.png"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="src-class-sec">
                <div className="cus-container">
                    <div className="ttl">
                        <h2 className="text-center">Search by Class</h2>
                    </div>
                    <div className="row justify-content-center">
                        <ClassBox image={"/assets/images/game-icon.png"} name={'Game Asset'} />
                        <ClassBox image={"/assets/images/land-icon.png"} name={'Land Asset'} />
                        <ClassBox image={"/assets/images/mobility-icon.png"} name={'Mobility Asset'} />
                        <ClassBox image={"/assets/images/building-icon.png"} name={'Building Asset'} />
                        <ClassBox image={"/assets/images/pet-asset-icon.png"} name={'pet Asset'} />
                        <ClassBox image={"/assets/images/costumeasset-icon.png"} name={'Costume Asset'} />
                        <ClassBox image={"/assets/images/Interrior-icon.png"} name={'Interior Design Asset'} />
                        <ClassBox image={"/assets/images/service-icon.png"} name={'Service Asset'} />
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}