import React from "react";
import { Routes, Route } from "react-router-dom";
import { PublicRoute } from './Routes';
import { Home } from './views/Auth/Home';
import { Minting } from "./views/Auth/Minting";
import { AuctionPage } from "./views/Auth/Auction/AuctionPage";
import { Creator } from "./views/Auth/Creator";
import { MultipleSelectProvider } from "./contexts/MultipleSelectContext";
import { MostViewPage } from "./views/Auth/MarketPlace/MostViewPage";
import { NewListPage } from "./views/Auth/MarketPlace/NewListPage";
import { RecentDealPage } from "./views/Auth/MarketPlace/RecentDealPage";
import { DetailPage } from "./views/Auth/MarketPlace/DetailPage";
import { BidPage } from "./views/Auth/Auction/BidPage";
import { MyNft } from "./views/Auth/MyNFTs";
import { SingleNftDelist } from "./views/Auth/DelistNft/SingleNftDelist";
import { MultipleNftDelist } from "./views/Auth/DelistNft/MultipleNftDelist";
import { SingleNftSell } from "./views/Auth/SellNft/SingleNft";
import { MultipleNftSell } from "./views/Auth/SellNft/MultipleNft";
import { DetailsPage } from "./views/Auth/SendNft/SingleNft/DetailsPage";
import { NextPage } from "./views/Auth/SendNft/SingleNft/NextPage";
import { SendPage } from "./views/Auth/SendNft/SingleNft/SendPage";
import { SelectionListPage } from "./views/Auth/SendNft/MutipleNft/SelectionListPage";
import { NextPageMultiple } from "./views/Auth/SendNft/MutipleNft/NextPageMultiple";
import { SendPageMultiple } from "./views/Auth/SendNft/MutipleNft/SendPageMultiple";
import { ClosePage } from "./views/Auth/Auction/ClosePage";
import { BuyPage } from "./views/Auth/Auction/BuyPage";


function App() {
  return (
    <MultipleSelectProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/matketplace/most-viewed" element={<PublicRoute><MostViewPage /></PublicRoute>} />
        <Route path="/matketplace/new-listing" element={<PublicRoute><NewListPage /></PublicRoute>} />
        <Route path="/matketplace/recent-deals" element={<PublicRoute><RecentDealPage /></PublicRoute>} />
        <Route path="/matketplace/asset-detail" element={<PublicRoute><DetailPage /></PublicRoute>} />

        <Route path="/my-nfts" element={<PublicRoute><MyNft /></PublicRoute>} />

        <Route path="/my-nfts/send-nft" element={<PublicRoute><DetailsPage /></PublicRoute>} />
        <Route path="/my-nfts/send-nft-send" element={<PublicRoute><NextPage /></PublicRoute>} />
        <Route path="/my-nfts/send-nft-send-next" element={<PublicRoute><SendPage /></PublicRoute>} />

        <Route path="/my-nfts/sell-nft" element={<PublicRoute><SingleNftSell /></PublicRoute>} />
        <Route path="/my-nfts/multiple-nft-sell" element={<PublicRoute><MultipleNftSell /></PublicRoute>} />

        <Route path="/my-nfts/multiple-nft" element={<PublicRoute><SelectionListPage /></PublicRoute>} />
        <Route path="/my-nfts/multiple-nft-send" element={<PublicRoute><NextPageMultiple/></PublicRoute>} />
        <Route path="/my-nfts/multiple-nft-send-next" element={<PublicRoute><SendPageMultiple /></PublicRoute>} />

        <Route path="/my-nfts/delist-nft" element={<PublicRoute><SingleNftDelist /></PublicRoute>} />

        <Route path="/my-nfts/multiple-nft-delist" element={<PublicRoute><MultipleNftDelist /></PublicRoute>} />

        <Route path="/minting" element={<PublicRoute><Minting /></PublicRoute>} />

        <Route path="/auction" element={<PublicRoute><AuctionPage /></PublicRoute>} />
        <Route path="/auction/bid" element={<PublicRoute><BidPage /></PublicRoute>} />
        <Route path="/auction/buy" element={<PublicRoute><BuyPage /></PublicRoute>} />
        <Route path="/auction/close" element={<PublicRoute><ClosePage /></PublicRoute>} />

        <Route path="/creator" element={<PublicRoute><Creator /></PublicRoute>} />

      </Routes>
    </MultipleSelectProvider>
  );
}

export default App;
