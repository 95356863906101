import React from 'react';

export const History = () => {

    return(
        <>
         <div className="sitettl pt-3">
                            <h2>Transaction History</h2>
                        </div>
                        <div className="history-panel">
                            <div className="box">
                                <span>TXN</span>
                                <p>0xaf26...b0fa97</p>
                            </div>
                            <div className="box">
                                <span>To</span>
                                <p>0xaf26...b038ef</p>
                            </div>
                            <div className="box">
                                <span>From</span>
                                <p>0xaf26...b0fa97</p>
                            </div>
                            <div className="box">
                                <span>Price</span>
                                <p className="d-flex align-items-center"><img src="/assets/images/paly-icon.png" alt="" />&nbsp; 61</p>
                            </div>
                            <div className="box">
                                <span>Date</span>
                                <p>Dec 06, 2022 20:47:36</p>
                            </div>
                            <div className="box d-flex justify-content-end">
                                <button className="btn">NFT Purchase</button>
                            </div>
                        </div>
                        <div className="history-panel">
                            <div className="box">
                                <span>TXN</span>
                                <p>0xaf26...b0fa97</p>
                            </div>
                            <div className="box">
                                <span>To</span>
                                <p>0xaf26...b038ef</p>
                            </div>
                            <div className="box">
                                <span>From</span>
                                <p>0xaf26...b0fa97</p>
                            </div>
                            <div className="box">
                                <span>Price</span>
                                <p className="d-flex align-items-center"><img src="/assets/images/paly-icon.png" alt="" />&nbsp; 61</p>
                            </div>
                            <div className="box">
                                <span>Date</span>
                                <p>Dec 06, 2022 20:47:36</p>
                            </div>
                            <div className="box d-flex justify-content-end">
                                <button className="btn">NFT Purchase</button>
                            </div>
                        </div>
                        <div className="history-panel">
                            <div className="box">
                                <span>TXN</span>
                                <p>0xaf26...b0fa97</p>
                            </div>
                            <div className="box">
                                <span>To</span>
                                <p>0xaf26...b038ef</p>
                            </div>
                            <div className="box">
                                <span>From</span>
                                <p>0xaf26...b0fa97</p>
                            </div>
                            <div className="box">
                                <span>Price</span>
                                <p className="d-flex align-items-center"><img src="/assets/images/paly-icon.png" alt="" />&nbsp; 61</p>
                            </div>
                            <div className="box">
                                <span>Date</span>
                                <p>Dec 06, 2022 20:47:36</p>
                            </div>
                            <div className="box d-flex justify-content-end">
                                <button className="btn">NFT Sent</button>
                            </div>
                        </div>
        </>
    )
}