import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { VerificationModal } from "../../../../components/Modals/VerificationModal";
import { TabPanel } from "../../../../components/TabPanel";
export const MultipleNftSell = () => {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [labels, setLabels] = useState(false);
    const [labelstwo, setLabelstwo] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangelabels = (e) => {
        if (e.target.value === 'Declining') {
            setLabels(true);
        } else {
            setLabels(false);
        }
    }
    const handleChangelabelstwo = (e) => {
        if (e.target.value === 'Declining') {
            setLabelstwo(true);
        } else {
            setLabelstwo(false);
        }
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };

    return (
        <>
        <div className="body-full-height">
            <div className="sitePagewrap">
                <div className="cus-container">
                    <div className="sitettl">
                        <h2>Selection List</h2>
                    </div>
                    <div className="asset-tabheader">
                        <Tabs value={value} onChange={handleChange}>
                            <Tab style={{ textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="Fixed Price" />
                            <Tab style={{ textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="Auction" />
                        </Tabs>
                    </div>
                    <TabPanel value={value} index={0}>
                        <div className="selection-list-table pt-3">
                            <div className="table-responsive">
                                <table className="table comm-table">
                                    <thead>
                                        <tr>
                                            <th>Character Name</th>
                                            <th>Token ID</th>
                                            <th>Class</th>
                                            <th>Creator</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="tb-pro-name">
                                                    <span className="tham"><img src="/assets/images/cart-pro-img1.png" alt="" /></span>
                                                    <p>Exythrabdg 1</p>
                                                </div>
                                            </td>
                                            <td>475684</td>
                                            <td>Land</td>
                                            <td>Nuritopia</td>
                                            <td>
                                                <div className="tb-price-wrap bor-cell">
                                                    <span className="tb-price">
                                                        <img src="/assets/images/paly-icon.png" alt="" /> 200.00 <em>&nbsp;|</em>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="tb-pro-name">
                                                    <span className="tham"><img src="/assets/images/cart-pro-img2.png" alt="" /></span>
                                                    <p>Exythrabdg 1</p>
                                                </div>
                                            </td>
                                            <td>475684</td>
                                            <td>Land</td>
                                            <td>Nuritopia</td>
                                            <td>
                                                <div className="tb-price-wrap bg-cell">
                                                    <span className="tb-price">
                                                        <img src="/assets/images/paly-icon.png" alt="" /> 200.00
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="price-table-info">
                            <div className="table-info-wrap">
                                <table className="table tb-bg-none">
                                    <tbody>
                                        <tr>
                                            <td><span>Gas Fee</span></td>
                                            <td><p>50.00 NBLU</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="bottom-buy-status-bar mt-4">
                            <div className="buy-status-wrap justify-content-end">
                                <div className="comm-btn">
                                    <button onClick={handleOpen}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className="selection-list-table pt-3">
                            <div className="table-responsive">
                                <table className="table comm-table">
                                    <thead>
                                        <tr>
                                            <th>Character Name</th>
                                            <th>Token ID</th>
                                            <th>Class</th>
                                            <th>Creator</th>
                                            <th>Duration</th>
                                            <th>Method</th>
                                            <th>Starting Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="tb-pro-name">
                                                    <span className="tham"><img src="/assets/images/cart-pro-img1.png" alt="" /></span>
                                                    <p>Exythrabdg 1</p>
                                                </div>
                                            </td>
                                            <td>475684</td>
                                            <td>Land</td>
                                            <td>Nuritopia</td>
                                            <td>
                                                <div className="tb-form-field">
                                                    <select className="form-control">
                                                        <option>7 Days</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="tb-form-field">
                                                    <select className="form-control" onChange={handleChangelabels}>
                                                        <option value='Highest'>Selling to Highest Bidder</option>
                                                        <option value='Declining'>Selling with Declining Price</option>
                                                    </select>
                                                </div>
                                            </td>
                                            {labels ?
                                                <td>
                                                    <div className="tb-form-field mb-2">
                                                        <img className="play-icon" src="/assets/images/paly-icon.png" alt="" />
                                                        <input type="text" className="form-control ex-pl" placeholder="Starting Price" />
                                                    </div>
                                                    <div className="tb-form-field">
                                                        <img className="play-icon" src="/assets/images/paly-icon.png" alt="" />
                                                        <input type="text" className="form-control ex-pl" placeholder="End Price" />
                                                    </div>
                                                </td>
                                                :
                                                <td>
                                                    <div className="tb-form-field">
                                                        <div className="tb-price-wrap bor-cell mb-2">
                                                            <span className="tb-price">
                                                                <img src="/assets/images/paly-icon.png" alt="" /> 200.00 <em>&nbsp;|</em>
                                                            </span>
                                                        </div>
                                                        <select className="form-control">
                                                            <option>Reserve Price (optional) </option>
                                                        </select>
                                                    </div>
                                                </td>}
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="tb-pro-name">
                                                    <span className="tham"><img src="/assets/images/cart-pro-img2.png" alt="" /></span>
                                                    <p>Exythrabdg 1</p>
                                                </div>
                                            </td>
                                            <td>475684</td>
                                            <td>Land</td>
                                            <td>Nuritopia</td>
                                            <td>
                                                <div className="tb-form-field">
                                                    <select className="form-control">
                                                        <option>7 Days</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="tb-form-field">
                                                    <select className="form-control" onChange={handleChangelabelstwo}>
                                                        <option value='Highest'>Selling to Highest Bidder</option>
                                                        <option value='Declining'>Selling with Declining Price</option>
                                                    </select>
                                                </div>
                                            </td>
                                            {labelstwo ?
                                                <td>
                                                    <div className="tb-form-field mb-2">
                                                        <img className="play-icon" src="/assets/images/paly-icon.png" alt="" />
                                                        <input type="text" className="form-control ex-pl" placeholder="Starting Price" />
                                                    </div>
                                                    <div className="tb-form-field">
                                                        <img className="play-icon" src="/assets/images/paly-icon.png" alt="" />
                                                        <input type="text" className="form-control ex-pl" placeholder="End Price" />
                                                    </div>
                                                </td>
                                                :
                                                <td>
                                                    <div className="tb-form-field">
                                                        <div className="tb-price-wrap bor-cell mb-2">
                                                            <span className="tb-price">
                                                                <img src="/assets/images/paly-icon.png" alt="" /> 200.00 <em>&nbsp;|</em>
                                                            </span>
                                                        </div>
                                                        <select className="form-control">
                                                            <option>Reserve Price (optional) </option>
                                                        </select>
                                                    </div>
                                                </td>}
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="price-table-info">
                            <div className="table-info-wrap">
                                <table className="table tb-bg-none">
                                    <tbody>
                                        <tr>
                                            <td><span>Service Fee</span></td>
                                            <td><p>2.5 %</p></td>
                                        </tr>
                                        <tr>
                                            <td><span>Creator’s Fees</span></td>
                                            <td><p>3 %</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="bottom-buy-status-bar mt-4">
                            <div className="buy-status-wrap justify-content-end">
                                <div className="comm-btn">
                                    <button onClick={handleOpen}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </div>
            </div>
            <VerificationModal open={open}
                onClose={handleClose} />
        </>
    )
}