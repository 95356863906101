import React from "react";
import { AuctionBox } from "../../../components/AuctionBox";

export const AuctionPage = () => {

    return (
        <>
            <div className="cat-sec">
                <div className="cus-container">
                    <div className="cat-ttl no-border-b pb-0">
                        <h2>Auction</h2>
                        <div className="cat-filter">
                            <select className="form-control mr-2">
                                <option value="">All</option>
                                <option value="">Most Viewed</option>
                                <option value="">Recent Viewed</option>
                            </select>
                            <select className="form-control">
                                <option value="">Most Viewed</option>
                                <option value="">Recent Viewed</option>
                            </select>
                        </div>
                    </div>
                    <div className="cat-list-wrap">
                        <div className="row">
                            <AuctionBox image={"/assets/images/cat-img1.png"} value={'bid'} />
                            <AuctionBox image={"/assets/images/cat-img2.png"} value={'closed'}/>
                            <AuctionBox image={"/assets/images/cat-img3.png"} value={'buy'} />
                            <AuctionBox image={"/assets/images/cat-img4.png"} value={'bid'} />
                            <AuctionBox image={"/assets/images/cat-img5.png"} value={'buy'} />
                            <AuctionBox image={"/assets/images/cat-img6.png"} value={'closed'}/>
                            <AuctionBox image={"/assets/images/cat-img7.png"} value={'buy'} />
                            <AuctionBox image={"/assets/images/cat-img8.png"} value={'bid'} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}