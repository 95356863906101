import React from "react";
import { AssetBox } from "../../../components/AssetBox";

export const Creator = () => {

    return (
        <>
            <div className="cat-sec">
                <div className="cus-container">
                    <div className="cat-ttl position-center bor-bt-0 pt-4">
                        <h2 className="d-flex align-items-center"><img className="mr-2" src="/assets/images/sm-logo.png" alt="" /> Nurithopia</h2>
                        <div className="cat-filter">
                            <select className="form-control">
                                <option value="">Lowest Price</option>
                                <option value="">Highest Price</option>
                                <option value="">Land</option>
                                <option value="">Mobility</option>
                                <option value="">Game</option>
                                <option value="">Costume</option>
                                <option value="">Building</option>
                                <option value="">Pet</option>
                                <option value="">Service</option>
                            </select>
                        </div>
                    </div>
                    <div className="cat-list-wrap">
                        <div className="row">
                            <AssetBox image={"/assets/images/cat-img1.png"} />
                            <AssetBox image={"/assets/images/cat-img2.png"} />
                            <AssetBox image={"/assets/images/cat-img3.png"} />
                            <AssetBox image={"/assets/images/cat-img4.png"} />
                            <AssetBox image={"/assets/images/cat-img5.png"} />
                            <AssetBox image={"/assets/images/cat-img6.png"} />
                            <AssetBox image={"/assets/images/cat-img7.png"} />
                            <AssetBox image={"/assets/images/cat-img8.png"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}