import React, { useState } from "react";

import { VerificationModal } from "./VerificationModal";

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function CustomizeDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle  {...other}>
        {children}
        {onClose ? (
          <IconButton
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

export const BidModal = (props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };
    return (
        <div>
            <Dialog className="commModal"
                open={props.open}
                onClose={props.onClose}>
                    <CustomizeDialogTitle onClose={props.onClose}>
                        <span>Bid</span>
                    </CustomizeDialogTitle>
                <DialogContent>
                    <div className="modalAssetImg">
                        <img src="/assets/images/modalasset-img.png" alt="" />
                    </div>
                    <div className="modalAssetInfo">
                        <div className="assetInfottl">
                            <h4>Land L</h4>
                            <p>Would you like to buy?</p>
                        </div>
                        <div className="modalPriceInfo">
                            <div className="panel">
                                <div className="ttl">
                                    <p>Price</p>
                                </div>
                                <div className="price">
                                    <img src="/assets/images/paly-icon.png" alt="" />
                                    <span>50.00</span>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="ttl">
                                    <p>My Balance</p>
                                </div>
                                <div className="price">
                                    <img src="/assets/images/paly-icon.png" alt="" />
                                    <strong>2050.00</strong>
                                </div>
                            </div>
                            <div className="panel">
                                <div className="ttl">
                                    <p>Price</p>
                                </div>
                                <div className="gassFree">
                                    <span>Free</span>
                                </div>
                            </div>
                            <div className="panel durationPanel">
                                <div className="ttl">
                                    <p>Duration</p>
                                </div>
                                <div className="durationDiv">
                                    <div className="left">
                                        <select>
                                            <option>1 Days</option>
                                            <option>2 Days</option>
                                            <option>3 Days</option>
                                        </select>
                                    </div>
                                    <div className="right">
                                        <div className="date">
                                            <p>Jan 1  2023</p>
                                        </div>
                                        <div className="time">
                                            <p>2:04 PM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={() => { handleOpen(); props.onClose()}} className="hlf-btn modalBtn">
                        Make Offer
                    </button>
                    <button onClick={props.onClose} className="hlf-btn modalBtn borderBtn">
                        cancel
                    </button>
                </DialogActions>
            </Dialog>
            <VerificationModal open={open} onClose={handleClose} />
        </div>
    )
}