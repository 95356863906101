import React from 'react';

export const Mint = () => {

    return (
        <div className="creatMinting">
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="creat-box">
                        <h2>Create New Item</h2>
                        <p><sup>*</sup> Required Fields</p>
                        <strong>Image, Video, Audio or 3D Model</strong>
                        <div className="cus-upload">
                            <input type="file" id="upload" name="" value="" />
                            <label htmlFor="upload">
                                <span>
                                    <span className="icon">
                                        <img src="/assets/images/gallery-icon.png" />
                                    </span>
                                    <p>File types supported :<span>JPG, PNG, GIF, SVG, MP4, WAV, OGG, GLB, GLTF.Max.</span> <span>File size : 100 MB</span></p>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="creatMintingForm commonfr">
                        <div className="form-group">
                            <label>Name <sup>*</sup></label>
                            <input type="text" className="form-control" placeholder="Item Name" />
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <p>The description will included on the item’s details page underneath it’s image.</p>
                            <textarea className="form-control" placeholder="provide a detailed description of your item."></textarea>
                        </div>
                        <div className="form-group">
                            <label>Collection</label>
                            <p>Create a new collection</p>
                            <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                            <p>Select an existing collection</p>
                            <select className="form-control">
                                <option>Collection 1</option>
                                <option>Collection 2</option>
                                <option>Collection 3</option>
                                <option>Collection 4</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Keywords</label>
                            <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Supply</label>
                            <select className="form-control">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Blockchain</label>
                            <select className="form-control">
                                <option>Ethereum</option>
                            </select>
                        </div>
                        <div className="btn-wrap">
                            <button type="submit" className="btn">Create</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}