import React, { useState } from "react";
import { TabPanel } from "../../../components/TabPanel";

import { Tab, Tabs } from "@mui/material";
import { NuriMint } from "./NuriMint";
import { Mint } from "./Mint";
import { MyCreation } from "./MyCreation";


export const Minting = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <div className="mynfts sitePagewrap body-full-height">
            <div className="cus-container">
                <div className="mynftsWrap">
                    <div className="siteTabhead">
                        <Tabs value={value} onChange={handleChange}>
                            <Tab style={{textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="NuriMint" />
                            <Tab style={{textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="Mint" />
                            <Tab style={{textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="My Creation" />
                        </Tabs>
                    </div>
                    <TabPanel value={value} index={0}>
                       <NuriMint /> 
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Mint />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                       <MyCreation />
                    </TabPanel>
                </div>
            </div>
        </div>
    )
}