import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RecentAddressModal } from "../../../../components/Modals/RecentAddressModal";
import { SearchIDModal } from "../../../../components/Modals/SearchIDModal";

export const NextPageMultiple = () => {
    const [search, setSearch] = useState(false);
    const [address, setAddress] = useState(false);
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/my-nfts/multiple-nft-send-next');
    }
    const handleOpenSearch = () => {
        setSearch(true);
    };
    const handleCloseSearch = () => {
        setSearch(false);
    };
    const handleOpenAddress = () => {
        setAddress(true);
    };
    const handleCloseAddress = () => {
        setAddress(false);
    };
    return (
        <>
            <div className="selection-list-sec">
                <div className="cus-container">
                    <h2>Selection List</h2>
                    <div className="selection-list-table">
                        <div className="table-responsive"></div>
                        <table className="table comm-table">
                            <thead>
                                <tr>
                                    <th>Character Name</th>
                                    <th>Token ID</th>
                                    <th>Class</th>
                                    <th>Creator</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="tb-pro-name">
                                            <span className="tham"><img src="/assets/images/cart-pro-img1.png" alt="" /></span>
                                            <p>Exythrabdg 1</p>
                                        </div>
                                    </td>
                                    <td>475684</td>
                                    <td>Land</td>
                                    <td>Nuritopia</td>
                                    <td><span className="tb-price"><img src="images/paly-icon.png" alt="" /> 200.00</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="tb-pro-name">
                                            <span className="tham"><img src="/assets/images/cart-pro-img2.png" alt="" /></span>
                                            <p>Exythrabdg 1</p>
                                        </div>
                                    </td>
                                    <td>475684</td>
                                    <td>Land</td>
                                    <td>Nuritopia</td>
                                    <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="tb-pro-name">
                                            <span className="tham"><img src="/assets/images/cart-pro-img3.png" alt="" /></span>
                                            <p>Exythrabdg 1</p>
                                        </div>
                                    </td>
                                    <td>475684</td>
                                    <td>Land</td>
                                    <td>Nuritopia</td>
                                    <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="price-table-info">
                            <div className="table-info-wrap">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td><span>Gas Fee</span></td>
                                            <td><p>50.00 NBLU</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="selection-list-filterbar">
                <div className="cus-container">
                    <div className="border-wrap topBor">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="comm-srcbar mb-0">
                                    <input type="text" className="form-control" />
                                    <div className="icon">
                                        <img src="/assets/images/src-icon.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className="row">
                                    <div className="col-md-4 col-ms-12">
                                        <div className="filter-btn-panel mb-0"  onClick={handleOpenSearch}>
                                            <div className="box">
                                                <div className="icon">
                                                    <img src="/assets/images/wt-src-icon.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    <p>Search ID</p>
                                                </div>
                                            </div>
                                            <div className="arrow">
                                                <img src="/assets/images/right-arrow.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-ms-12">
                                        <div className="filter-btn-panel mb-0"  onClick={handleOpenAddress}>
                                            <div className="box">
                                                <div className="icon">
                                                    <img src="/assets/images/address-icon.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    <p>Recent address</p>
                                                </div>
                                            </div>
                                            <div className="arrow">
                                                <img src="/assets/images/right-arrow.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-ms-12">
                                        <div className="filter-btn-panel mb-0">
                                            <div className="box">
                                                <div className="icon">
                                                    <img src="/assets/images/paste-icon.png" alt="" />
                                                </div>
                                                <div className="text">
                                                    <p>Paste</p>
                                                </div>
                                            </div>
                                            <div className="arrow">
                                                <img src="/assets/images/right-arrow.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-buy-status-bar">
                <div className="cus-container">
                    <div className="buy-status-wrap justify-content-end">
                        <div className="comm-btn">
                            <button onClick={handleClick}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
            <SearchIDModal
                open={search}
                onClose={handleCloseSearch} />
            <RecentAddressModal
                open={address}
                onClose={handleCloseAddress} />
        </>
    )
}