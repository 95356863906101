import React from "react";
import { CatBox } from "../../../components/CatBox";

export const NewListPage = () => {

    return (
        <div className="cat-sec">
            <div className="cus-container">
                <div className="cat-ttl">
                    <h3>New Listing (12 items)</h3>
                    <div className="cat-filter">
                        <select className="form-control mr-2">
                            <option value="">All Classes</option>
                            <option value="">Most Viewed</option>
                            <option value="">Recent Viewed</option>
                        </select>
                        <select className="form-control">
                            <option value="">Most Viewed</option>
                            <option value="">Recent Viewed</option>
                        </select>
                    </div>
                </div>
                <div className="cat-panel">
                    <div className="cat-list-wrap">
                        <div className="row">
                            <CatBox image={"/assets/images/cat-img1.png"} />
                            <CatBox image={"/assets/images/cat-img2.png"} />
                            <CatBox image={"/assets/images/cat-img3.png"} />
                            <CatBox image={"/assets/images/cat-img4.png"} />
                            <CatBox image={"/assets/images/cat-img5.png"} />
                            <CatBox image={"/assets/images/cat-img6.png"} />
                            <CatBox image={"/assets/images/cat-img7.png"} />
                            <CatBox image={"/assets/images/cat-img8.png"} />
                            <CatBox image={"/assets/images/cat-img9.png"} />
                            <CatBox image={"/assets/images/cat-img10.png"} />
                            <CatBox image={"/assets/images/cat-img11.png"} />
                            <CatBox image={"/assets/images/cat-img12.png"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}