import React from "react";

export const ClassBox = (props) => {

    return(
        <div className="col-md-4 col-sm-12">
          <div className="src-class-box">
            <div className="icon">
              <img src={props.image} alt="" />
            </div>
            <div className="text-box">
              <strong>{props.name}</strong>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>
            </div>
          </div>
        </div>
    )
}