import React, { useState } from "react";
import { ViewModal } from "./Modals/ViewModal";

export const CreationBox = (props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);

    };
    return (
        <>
            <div className="col-md-3 col-sm-12">
                <div className="cat-box">
                    <div className="img-box">
                        <img src={props.image} alt="" />
                    </div>
                    <div className="cat-info">
                        <div className="top-bar">
                            <div className="panel">
                                <p className="creation-text">Exythrabdg</p>
                            </div>
                            <div className="number">
                                <p>475684</p>
                            </div>
                        </div>
                        {/* <p className="creation-text">Exythrabdg</p> */}
                    </div>
                    <div className="bottom-panel">
                        <div className="plus-icon" onClick={handleOpen}>
                            <p className="creation-text">View</p>
                        </div>
                        <div className="lt-side">
                            <span>27.02.2023</span>
                        </div>
                    </div>
                </div>
            </div>

            <ViewModal open={open} onClose={handleClose} />
        </>
    );
};