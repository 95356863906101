import React from "react";
import { Link, useNavigate } from 'react-router-dom';

export const AuctionBox = (props) => {
    const navigate = useNavigate();
    const handleClickCreator = () => {
        navigate('/creator')
    }
    const handleClick = () => {
        props.value !== 'bid' ? props.value === 'closed' ? navigate('/auction/close') : navigate('/auction/buy')
            : navigate('/auction/bid');
    }
    return (
        <>
            <div className="col-md-3 col-sm-12">
                <div className="cat-box">
                    <div className="img-box">
                        <div className="select-cat">
                            <img src="/assets/images/select-icon.png" alt="" />
                        </div>
                        <img src={props.image} alt="" />
                    </div>
                    <div className="cat-info">
                        <div className="top-bar">
                            <div className="panel">
                                <div className="icon">
                                    <img src="/assets/images/n-logo-img.png" alt="" />
                                </div>
                                <div className="text" onClick={handleClickCreator}>
                                    <p>Nurithopia</p>
                                </div>
                            </div>
                            <div className="number">
                                <p>475684</p>
                            </div>
                        </div>
                        <p className="creation-text">Exythrabdg</p>
                    </div>
                    <div className="bottom-panel">
                        <div className="btnField" onClick={handleClick}>
                            {props.value !== 'buy' ? props.value === 'closed' ? <button className="closed">Closed</button>
                                : <button className="bid">Bid</button>
                                : <button>Buy Now</button>}
                        </div>
                        <div className="lt-side">
                            <img src="/assets/images/paly-icon.png" alt="" />
                            <span>250</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
