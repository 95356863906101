import React from "react";
import { useNavigate } from "react-router-dom";

export const SelectionListPage = () => {
    const navigate = useNavigate();
    const handleClickSend = () => {
        navigate('/my-nfts/multiple-nft-send');
    }
    const handleClickSell = () => {
        navigate('/my-nfts/multiple-nft-sell');
    }
    return (
        <>
        <div className="body-full-height">
            <div className="selection-list-sec">
                <div className="cus-container">
                    <h2>Selection List</h2>
                    <div className="selection-list-table">
                        <div className="table-responsive">
                            <table className="table comm-table">
                                <thead>
                                    <tr>
                                        <th>Character Name</th>
                                        <th>Token ID</th>
                                        <th>Class</th>
                                        <th>Creator</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="tb-pro-name">
                                                <span className="tham"><img src="/assets/images/cart-pro-img1.png" alt="" /></span>
                                                <p>Exythrabdg 1</p>
                                            </div>
                                        </td>
                                        <td>475684</td>
                                        <td>Land</td>
                                        <td>Nuritopia</td>
                                        <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tb-pro-name">
                                                <span className="tham"><img src="/assets/images/cart-pro-img2.png" alt="" /></span>
                                                <p>Exythrabdg 1</p>
                                            </div>
                                        </td>
                                        <td>475684</td>
                                        <td>Land</td>
                                        <td>Nuritopia</td>
                                        <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tb-pro-name">
                                                <span className="tham"><img src="/assets/images/cart-pro-img3.png" alt="" /></span>
                                                <p>Exythrabdg 1</p>
                                            </div>
                                        </td>
                                        <td>475684</td>
                                        <td>Land</td>
                                        <td>Nuritopia</td>
                                        <td><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-buy-status-bar">
                <div className="cus-container">
                    <div className="buy-status-wrap justify-content-end no-border-tp">
                        <div className="comm-btn d-flex">
                            <button className="send-btn mr-3" onClick={handleClickSend}>Send</button>
                            <button className="sell-btn" onClick={handleClickSell}>Sell</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}