import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { React, useState } from "react";
import { VerificationModal } from "../../../components/Modals/VerificationModal";

export const CartModal = (props) => {
    const [open, setOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleIncrement = () => {
        setQuantity(quantity+1);
    }
    const handleDecrement = () => {
        setQuantity(quantity-1);
    }

    return (
        <>
            <Dialog className="cart-modal-sec"
                open={props.open}
                onClose={props.onClose}>
                <DialogTitle>
                    <span>List</span>
                </DialogTitle>
                <DialogContent>
                    <div className="cart-list-wrap">
                        <div className="table-responsive">
                            <table className="table comm-table">
                                <thead>
                                    <tr>
                                        <th>Character Name</th>
                                        <th>Token ID</th>
                                        <th align="center">Price</th>
                                        <th align="center">Quantity</th>
                                        <th align="center">Total Price</th>
                                        <th align="center"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="tb-pro-name">
                                                <span className="tham"><img src="/assets/images/cart-pro-img1.png" alt="" /></span>
                                                <p>Exythrabdg 1</p>
                                            </div>
                                        </td>
                                        <td>475684</td>
                                        <td align="center"><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                        <td align="center">
                                            <p className="qty">
                                                <button className="qtyminus" aria-hidden="true"  onClick={handleDecrement}><i class="fa fa-minus"></i></button>
                                                <input type="number" name="qty" id="qty" min="1" max="10" step="1" value={quantity} />
                                                <button className="qtyplus" aria-hidden="true" onClick={handleIncrement}><i class="fa fa-plus"></i></button>
                                            </p>
                                        </td>
                                        <td align="center"><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                        <td align="center"><span className="crose-icon" ><i class="fa fa-times"></i></span></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tb-pro-name">
                                                <span className="tham"><img src="/assets/images/cart-pro-img2.png" alt="" /></span>
                                                <p>Exythrabdg 1</p>
                                            </div>
                                        </td>
                                        <td>475684</td>
                                        <td align="center"><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                        <td align="center">
                                            <p className="qty">
                                                <button className="qtyminus" aria-hidden="true"><i class="fa fa-minus"></i></button>
                                                <input type="number" name="qty" id="qty" min="1" max="10" step="1" value="1" />
                                                <button className="qtyplus" aria-hidden="true"><i class="fa fa-plus"></i></button>
                                            </p>
                                        </td>
                                        <td align="center"><span className="tb-price"><img src="/assets/images/paly-icon.png" alt="" /> 200.00</span></td>
                                        <td align="center"><span className="crose-icon"><i class="fa fa-times"></i></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="price-table-info">
                            <div className="table-info-wrap pr-3">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td><span>My Balance</span></td>
                                            <td><p>2500.00 NBLU</p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-info-wrap pl-3">
                                <table className="table border-non">
                                    <tbody>
                                        <tr>
                                            <td><span>Gas Free</span></td>
                                            <td><p>50.00 NBLU</p></td>
                                        </tr>
                                        <tr>
                                            <td><span><strong>Total </strong></span></td>
                                            <td><p><strong>550.00 NBLU</strong></p></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="justify-content-center">
                    <div className="comm-btn justify-content-center">
                        <button className="mr-3" onClick={props.onClose}>Cancel</button>
                        <button onClick={() => { handleOpen(); props.onClose() }}>Next</button>
                    </div>
                </DialogActions>
            </Dialog>
            <VerificationModal open={open} onClose={handleClose} />

        </>
    )
}