import React, { useState } from 'react';
import { BidModal } from '../../../components/Modals/BidModal';

export const BidPage = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return(
        <>
            <div className="asset-details-sec">
                <div className="cus-container">
                    <div className="asset-details-wrap">
                        <div className="ttl">
                            <h2>Land L</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <div className="img-box">
                                    <img src="/assets/images/asset-details.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-7 col-sm-12">
                                <div className="asset-details-info">
                                    <div className="details-info-list">
                                        <div className="row">
                                            <div className="col-md-8 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Character Name</span>
                                                    <p>Land L</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Total Items</span>
                                                    <p>77</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Token ID</span>
                                                    <p>44477</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Availability</span>
                                                    <p>2</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Owner</span>
                                                    <p>0x564562345878965422155556654422366554</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Class</span>
                                                    <p>Land</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Creator name</span>
                                                    <p>Nuritopia</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Chain</span>
                                                    <p>BSC</p>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Link</span>
                                                    <p>https://www.nuritopia/NFT/Trade/44477.html</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <div className="asset-details-box">
                                                    <span>Dictionary</span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit i</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price-buy-wrap">
                                        <div className="price">
                                            <p>Price</p>
                                            <div className="price-box">
                                                <img src="/assets/images/paly-icon.png" alt="" />
                                                <span>50.00</span>
                                            </div>
                                        </div>
                                        <div className="buy-btn">
                                            <div className="priceBtnGroup">
                                                <input type="text" className="form-control" placeholder="price"/>
                                                <select>
                                                    <option>USDC</option>
                                                </select>
                                                <p>Total offer amount. 0 USDC</p>
                                            </div>
                                            <button onClick={handleOpen}>Make Offer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="history-table">
                            <h2>Bid History</h2>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>TXN</th>
                                            <th>From</th>
                                            <th>Duration</th>
                                            <th>Price</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Ox1374..1173FD</td>
                                            <td>0X8C01..BD38ef</td>
                                            <td>0XAF26..B05A97</td>
                                            <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                            <td>Dec 06, 2022 20:47:36</td>
                                        </tr>
                                        <tr>
                                            <td>Ox1374..1173FD</td>
                                            <td>0X8C01..BD38ef</td>
                                            <td>0XAF26..B05A97</td>
                                            <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                            <td>Dec 06, 2022 20:47:36</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="history-table boder-tp-none padding-top-0 margin-top-0">
                            <h2>Transaction History</h2>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>TXN</th>
                                            <th>To</th>
                                            <th>From</th>
                                            <th>Price</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Ox1374..1173FD</td>
                                            <td>0X8C01..BD38ef</td>
                                            <td>0XAF26..B05A97</td>
                                            <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                            <td>Dec 06, 2022 20:47:36</td>
                                        </tr>
                                        <tr>
                                            <td>Ox1374..1173FD</td>
                                            <td>0X8C01..BD38ef</td>
                                            <td>0XAF26..B05A97</td>
                                            <td><span><img src="/assets/images/paly-icon.png" alt="" /> 61</span></td>
                                            <td>Dec 06, 2022 20:47:36</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* <button onClick={handleOpen}> next</button> */}
             <BidModal
                open={open}
                onClose={handleClose} 
            /> 
        </>
    )
}