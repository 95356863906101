import { React, useState } from "react";
import { TabPanel } from "../../../components/TabPanel";
import { Tab, Tabs } from "@mui/material";
import { fontFamily, textTransform } from "@mui/system";
import { Wallet } from "./Wallet";
import { Sale } from "./Sale";
import { History } from "./History";


export const MyNft = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div className="mynfts sitePagewrap body-full-height">
            <div className="cus-container">
                <div className="mynftsWrap">
                    <div className="siteTabhead">
                        <Tabs value={value} onChange={handleChange}>
                            <Tab style={{ textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="In Wallet" />
                            <Tab style={{ textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="On Sale" />
                            <Tab style={{ textTransform: "capitalize", fontFamily: "'Outfit', sans-serif" }} label="History" />
                        </Tabs>
                    </div>
                    <TabPanel value={value} index={0}>
                        <Wallet />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Sale />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                       <History />
                    </TabPanel>
                </div>
            </div>
        </div>
    )
}